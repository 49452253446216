@font-face {
  font-family: 'Radikal-bd';
  src: url('./assets/font/Radikal-Bold.ttf');
}

@font-face {
  font-family: 'Radikal-rg';
  src: url('./assets/font/Radikal-Regular.ttf');
}
@font-face {
  font-family: 'Gotham-rg';
  src: url('./assets/font/GothaProReg.otf');
}
@font-face {
  font-family: 'SF-mono';
  src: url('./assets/font/sf-mono-cufonfonts-webfont/SFMonoRegular.woff');
}
@font-face {
  font-family: 'sf-lt';
  src: url('./assets/font/sf-mono-cufonfonts-webfont/SFMonoLight.woff');
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Radikal-rg', sans-serif;
  font-weight: 40;
}
html {
  scroll-behavior: smooth;
}
.title {
  font-family: 'Radikal-bd';
  font-weight: 600;
  font-size: 40px;
  color: #222222;
}
.subtitle {
  font-family: 'Radikal-rg';
  font-weight: 300;
  font-size: 18px;
  color: #222222;
}
.hidden {
  overflow: hidden;
}
.font50 {
  font-size: 50px !important;
  @media (max-width: 770px) {
    font-size: 45px !important;
  }
}
.font10 {
  font-size: 10px !important;
}
.font40 {
  font-size: 40px !important;
}
.font18 {
  font-size: 18px !important;
}
.font20 {
  font-size: 20px !important;
}
.font14 {
  font-size: 14px !important;
}
.font25 {
  font-size: 25px !important;
}
.font-bold {
  font-family: 'Radikal-bd';
  font-weight: 600;
}
.font-light {
  font-family: 'Radikal-rg';
  font-weight: 300;
}
.navbar {
  padding: 1rem 1rem;
  .logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .nav-link {
    color: #231f20;
    font-size: 18px;
    margin: 0 1rem;
    @media (min-width: 770px) {
      &.active {
        border-bottom: #e04127 solid 3px;
        padding-bottom: 0px;
      }
    }
  }
}

.header {
  min-height: 100vh;
  background: url('./assets/img/bg.png');
  background-color: #f6c6be;
  background-size: cover;
  background-position: bottom;
  @media (max-width: 800px) {
    background: url('./assets/img/bg-sm.png');
    background-color: #f6c6be;
    background-size: cover;
    background-position: center;
    .girl-card {
      width: 100%;
    }
    .button-div {
      align-items: center !important;
    }
  }
  .girl-card {
    @media (min-width: 800px) {
      margin-top: 14vh;
    }
  }
}
.slider-wrapper {
  position: relative;
  .carousel-inner {
    min-height: 80vh;
    background-size: cover;
    .banner1 {
      background: url('./assets/img/bg1.jpg');
      background-size: cover;
      min-height: 80vh;
    }
    .banner2 {
      background: url('./assets/img/bg2.jpg');
      background-size: cover;
      min-height: 80vh;
      // background-position-y: -31vh;
    }
    .banner3 {
      background: url('./assets/img/bg3.jpg');
      min-height: 80vh;
      background-size: cover;
      background-position-y: 115vh;
      @media (max-width: 1100px) {
        background-position-y: 109vh;
      }
      @media (max-width: 800px) {
        background-position-y: 165vh;
      }
      @media (max-width: 500px) {
        background-position-y: 135vh;
      }
    }
    @media (max-width: 800px) {
      min-height: 50vh;
      .banner1,
      .banner2,
      .banner3 {
        min-height: 50vh;
      }
    }
  }
}
.btn {
  padding: 0.5rem 2rem;
}
.btn-white {
  border-radius: 30px;
  background: white;
  color: #e04127;
  &:hover {
    color: white;
  }
}
.btn-red {
  border-radius: 30px;
  background: #e04127;
  color: #fff;
  box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.165308);
  &:hover {
    color: white;
    background: darken($color: #e04127, $amount: 10);
  }
}
.btn-bg {
  padding: 1rem 4rem;
  font-size: 18px;
}
.short-about {
  background: #5f2628c7;
  font-size: 20px;
  padding: 1.5rem;
  width: 500px;
  color: white;
  max-width: 100%;
  @media (min-width: 800px) {
    border-radius: 100px 0px;
    position: absolute;
    padding: 2.5rem;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
footer {
  background-color: #e04127;
  background-size: cover;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  .logo {
    width: 73px;
    background: white;
    border-radius: 50%;
  }
  .social {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin-right: 0.7rem;
    background: #e4624d;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all ease;
    font-size: 20px !important;
    &.facebook {
      &:hover {
        background: #3b5998;
      }
    }
    &.twitter {
      &:hover {
        background: #00abf0;
      }
    }
    &.instagram {
      &:hover {
        background: #d6005c;
      }
    }
  }
  a {
    color: white;
    margin-right: 1rem;
    &:hover {
      color: white;
    }
  }
  .download {
    width: 50px;
    position: fixed;
    bottom: 4vh;
    z-index: 6;
    cursor: pointer;
    right: 0;
    .icon {
      text-align: center;
      margin-right: 0.9rem;
      background: #00000059;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-bottom: 1rem;
      font-size: 23px;
      color: #e04127;
      img {
        width: 20px;
      }
      &.apple {
        color: white;
        background: #e04127;
      }
      &.android {
        color: #e04127;
        background: white;
        border: #e04127 1px solid;
      }
    }
  }
}
.about-wrapper {
  .about-header {
    min-height: 70vh;
    background: url('./assets/img/about-bg.png');
    background-size: cover;
    background-color: #f6c6be;
    margin-bottom: 20vh;
  }
}

.privacy-wrapper {
  .privacy-header {
    min-height: 70vh;
    background: url('./assets/img/about-bg.png');
    background-size: cover;
    background-color: #f6c6be;
    // margin-bottom: 20vh;
  }
}
.bg-curve {
  min-height: 30vh;
}
.offices {
  margin: 15vh 0;
}

.start-wrapper {
  .start-header {
    min-height: 70vh;
    background: url('./assets/img/about-bg.png');
    background-size: cover;
    background-color: #f6c6be;
    background-position: center;
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 40vh;
    }
  }
  .form-wrapper {
    margin-top: -20vh;
    .card {
      box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.165308);
      padding: 2.5rem 2rem;
    }
    .form-header {
      background: #e04127;
      color: white;
      border-radius: 5px 5px 0 0;
    }
    .form-control {
      resize: none;
      &::placeholder {
        font-size: 13px;
      }
      &:focus {
        border-color: #e04127;
        outline: none;
        box-shadow: none;
      }
    }
    label {
      font-size: 14px;
      font-weight: 700;
    }
    .radio-container {
      font-weight: 300;
    }
  }
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: solid 1px #e5e5e5;
    border-radius: 50%;
    &:after {
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: white;
      content: '';
      position: absolute;
      display: none;
    }
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    background: linear-gradient(
      90deg,
      rgba(220, 68, 127, 1) 0%,
      rgba(248, 123, 67, 1) 99%
    );
    display: block;
  }
}
.form-intake {
  min-height: 50vh;
  margin-top: 15vh;
  background: #cdc2b6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #5f3913;
  @media (max-width: 800px) {
    .font40 {
      font-size: 30px !important;
    }
  }
}
.services-wrapper {
  .iconify {
    color: #ffdc97;
    font-size: 77px;
  }
}
.plan-section {
  margin: 20vh 0;
}

.ant-form-item-explain-error{
  font-size: 14px;
  color: #e04127;
}